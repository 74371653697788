body {
    margin: 0;
    padding: 0;
    background-color: #F5F5DC;
}

.cognitoText {
    color: white;
    text-shadow: 1px 1px 2px black;
    font-size: 1.5rem;
}

.amplify-tabs {
    flex-direction: row-reverse;
}

.amplify-tabs-item {
    border: 0;
}

.amplify-tabs-item[data-state=inactive] {
    border: 0;
    background-color: var(--amplify-components-button-primary-background-color);    
    color: var(--amplify-components-button-primary-color);
}