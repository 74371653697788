$color-high: #f00000;
$color-medium: #efb000;
$color-low: #ecec00;
$color-hover: #869bef10;
$color-selected-bg: #869bef50;

@mixin countStyle($bgColor) {
    padding: 0.1rem;    
    border-radius: 10px;
    border: 1px #333333a0 solid;
    min-width: 1.9rem;
    min-height: 1.9rem;
    color: #000000;
    background-color: $bgColor;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}  

.stack {    
    cursor: pointer;
    td:first-child {
        padding-left: 0.5rem;
        padding-right: 0.3rem;
    }
    td:last-child {
        text-align: center;
        padding-left: 0.1rem;
        padding-right: 0.5rem;
        min-width: 7rem;
    }
      
    .High {
        @include countStyle($color-high);
    }
    
    .Medium {
        @include countStyle($color-medium);
    }
    
    .Low {
        @include countStyle($color-low);
    }
}

.stack:hover {
    background-color: $color-hover;
}

.stack.selected {
    background: $color-selected-bg;
}



.issue {    
    cursor: pointer;
    td:first-child {
        padding-left: 0.5rem;
    }
    td:last-child {                
        padding-right: 1rem;
    }
    
    @mixin issueSeverityStyle($bgColor) {
        @include countStyle($bgColor);
        padding: 0;
    }

    .High {
        @include issueSeverityStyle($color-high);
    }
    
    .Medium {
        @include issueSeverityStyle($color-medium);
    }
    
    .Low {
        @include issueSeverityStyle($color-low);      
    }
}

.issue:hover {
    background-color: $color-hover;
}

.issue.selected {
    background: $color-selected-bg;
}